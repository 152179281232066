import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  LogoContainer,
  Para,
  Large,
} from "./styles";
import { Link } from "react-router-dom";

/**
 * Footer using i18 libary for change language (not use here)
 */

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <FooterSection id="footer">
        <Container>
          <Row justify="space-between">
            <Col lg={7} md={8} sm={12} xs={16}>
              <NavLink id="RouterNavLink" to="/">
                <LogoContainer>
                  <SvgIcon
                    src="logo.png"
                    aria-label="homepage"
                    width="100px"
                    height="100px"
                  />
                </LogoContainer>
                <br></br>
                <br></br>
                <LogoContainer>
                  <SvgIcon
                    src="NCBR_small.png"
                    aria-label="homepage"
                    width="160px"
                    height="30px"
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                </LogoContainer>
                <LogoContainer>
                  <SvgIcon
                    src="PW.png"
                    aria-label="homepage"
                    width="200px"
                    height="50px"
                  />
                </LogoContainer>
              </NavLink>
              <Para>Cybersecurity Division</Para>
              <Para>Faculty of Electronics</Para>
              <Para>and Information Technology</Para>
              <Para>Nowowiejska 15/19</Para>
              <Para>00-665 Warsaw, Poland</Para>
              <br></br>
              <br></br>
            </Col>
            <Col lg={7} md={8} sm={12} xs={16}>
              <Title>{t("Contact")}</Title>
              {/**
               * delete Label and add 'a' with links
               */}
              <Large>
                <div>
                  All the methods are brought to you by the{" "}
                  <a href="https://gitlab.com/leadomise-team">
                  <b>{"LEADOMISE team"} </b>at the
                    <b> Warsaw University of Technology</b>
                  </a>
                </div>
                <br></br>
                <div>
                  If you have any questions or recommendations, please contact{" "}
                  <a href="mailto:katarzyna.kaminska@pw.edu.pl">
                    <b>Katarzyna Kamińska</b>
                  </a>
                </div>
                <br></br>
                <div>
                  For technical support, please contact{" "}
                  <a href="mailto:support@leadomise.com">
                    <b>LEADOMISE support team</b>
                  </a>
                </div>
                <br></br>
                <div>
                  If you would like to join us, please contact{" "}
                  <a href="mailto:job@leadomise.com">
                    <b>LEADOMISE HR team</b>
                  </a>
                </div>
              </Large>
              <Large>
                {/**
                 * delete Label and add 'a' with links
                 */}
                {/* <a href="https://gitlab.com/leadomise-team/fragmentise">
                {t("please cite")} <b>FRAGMENTISE </b> method
                </a> */}
              </Large>
              <br></br>
            </Col>
            <Col lg={7} md={8} sm={10} xs={16}>
              <Title>{t("Disclaimer and Commercial Use")}</Title>
              <Large>
                <p>
                  Our methods are provided free to the academic community and
                  non-profit organizations in good faith without guarantees of
                  any kind. For more information about options for commercial
                  usage, please contact{" "}
                  <a href="mailto:katarzyna.kaminska@pw.edu.pl">
                    <b>Katarzyna Kamińska</b>
                  </a>
                </p>
              </Large>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
