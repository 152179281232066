import { StyledButton } from "./styles"; 
import { ButtonProps } from "../types";

export const Button = ({
  color,
  fixedWidth,
  children,
  onClick,
  goToLink,
  background__hover,

  /**
 * StyledButton get props from Button but I don't understand how {children} work
 */
}: ButtonProps) => (
  <StyledButton background__hover={background__hover} color={color} fixedWidth={fixedWidth} onClick={onClick || goToLink}>
    {children}
  </StyledButton>
);
