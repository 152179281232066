import React, { useCallback } from "react";
import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import Dropdown from "react-bootstrap/Dropdown";
import 'bootstrap/dist/css/bootstrap.css';
import './button.css';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  Wrapper,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  /**
   *  showDrawer - toggle to change hamburger visibility when it may be open
   */

  const showDrawer = () => {
    setVisibility(!visible);
  };

  /**
   *  onClose - toggle to change hamburger visibility when it may be close
   */

  const onClose = () => {
    setVisibility(!visible);
  };

  {
    /**
     *  Navbar logic
     * element were find id sections and move here ( I don't know how it work)
     */
  }

  const history = useHistory();

  const location = useLocation().pathname;
  console.log("location", location);

  let locationName: any;

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      if (location === "/") {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
          behavior: "smooth",
        });
        setVisibility(false);
      } else history.push("/");
    };
    return (
      <>
        <CustomNavLinkSmall>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
            {t("OUR TOOLS")}
            </Dropdown.Toggle>
            <Wrapper>
              <Dropdown.Menu>
              <Dropdown.Item href="#intro">LEADOMISE</Dropdown.Item>
              <Dropdown.Item href="#about">FRAGMENTISE</Dropdown.Item>
              <Dropdown.Item href="#coremise">COREMISE</Dropdown.Item>
              </Dropdown.Menu>
            </Wrapper>
          </Dropdown>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("team")}>
          <Span>{t("ABOUT")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("PROJECTS")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <a href="https://TherVira.com/Job">
          <Span>{t("JOIN US")}</Span>
          </a>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{
            width: "140px",
            fontWeight: "600",
            letterSpacing: "2px",
            marginLeft: "80px",
          }}
          onClick={() => scrollTo("footer")}
        >
          <Span>
            <Button>{t("CONTACT")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };
  {
    /**
     * Hamburger menu
     */
  }
  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.png" width="100px" height="100px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
